<template>

  <div class=" ">
    <!--{{total}}-->
    <div class="table_container">
      <table class="table  list-content">
        <thead class=" ">
        <tr>
          <th class="cursor_pointer sorted_row" style=" text-align: center;width: 160px;"
              @click.prevent="setSortVal()">
            Access Time

            <i v-if="['visitor_time_asc', 'created_at_asc'].indexOf(realtime_sorting) !== -1"
               class="first table_filter_caret fa fa-caret-up"></i>
            <i v-if="['visitor_time_desc', 'created_at_desc'].indexOf(realtime_sorting) !== -1"
               class="table_filter_caret fa fa-caret-down"></i>

          </th>
          <th class="cursor_pointer sorted_row" style="text-align: center;"
              @click.prevent="realtime_sorting = (realtime_sorting === 'ip_address_asc') ? 'ip_address_desc' : 'ip_address_asc'">
            IP
            <i v-if="realtime_sorting==='ip_address_asc'"
               class="first table_filter_caret fa fa-caret-up"></i>
            <i v-if="realtime_sorting==='ip_address_desc'"
               class="table_filter_caret fa fa-caret-down"></i>

          </th>
          <th class="cursor_pointer sorted_row" style="text-align: center;"
              @click.prevent="realtime_sorting = (realtime_sorting === 'country_asc') ? 'country_desc' : 'country_asc'">
            Country

            <i v-if="realtime_sorting==='country_asc'"
               class="first table_filter_caret fa fa-caret-up"></i>
            <i v-if="realtime_sorting==='country_desc'"
               class="table_filter_caret fa fa-caret-down"></i>

          </th>
          <th class="cursor_pointer sorted_row" style="text-align: center;"
              @click.prevent="realtime_sorting = (realtime_sorting === 'browser_asc') ? 'browser_desc' : 'browser_asc'">
            Browser

            <i v-if="realtime_sorting==='browser_asc'"
               class="first table_filter_caret fa fa-caret-up"></i>
            <i v-if="realtime_sorting==='browser_desc'"
               class="table_filter_caret fa fa-caret-down"></i>

          </th>
          <th class="cursor_pointer sorted_row" style="text-align: center;"
              @click.prevent="realtime_sorting = (realtime_sorting === 'os_name_asc') ? 'os_name_desc' : 'os_name_asc'">
            Platform

            <i v-if="realtime_sorting==='os_name_asc'"
               class="first table_filter_caret fa fa-caret-up"></i>
            <i v-if="realtime_sorting==='os_name_desc'"
               class="table_filter_caret fa fa-caret-down"></i>

          </th>
          <th class="cursor_pointer sorted_row" style="text-align: center;"
              @click.prevent="realtime_sorting = (realtime_sorting === 'referral_asc') ? 'referral_desc' : 'referral_asc'">
            Referral

            <i v-if="realtime_sorting==='referral_asc'"
               class="first table_filter_caret fa fa-caret-up"></i>
            <i v-if="realtime_sorting==='referral_desc'"
               class="table_filter_caret fa fa-caret-down"></i>

          </th>
        </tr>
        </thead>

        <tbody class="list-content-body">
        <tr v-if="loader">
          <td colspan="8">
            <clip-loader :size="size" :color="color"></clip-loader>
          </td>

        </tr>
        <tr v-else-if="list.length == 0">
          <td colspan="8">
            <p class="no-rows text-center" v-if="getAnalyticsDetail.search != ''">
              No Analytics exist for your searched query, please try something else.
            </p>
            <p class="no-rows text-center" v-else>
              <no-events-available></no-events-available>
            </p>
          </td>
        </tr>

        <template v-else>
          <tr v-for="analytic in list">
            <td>{{ analytic._source.visitor_time | relative }}</td>
            <td style="text-align: center;">{{ analytic._source.ip_address }}</td>
            <td style="text-align: center;" v-if="analytic._source.country">
              {{ analytic._source.country }}
            </td>
            <td style="text-align: center;" class="light_grey" v-else>
              N/A
            </td>
            <td style="text-align: center;">
              {{ analytic._source.browser }}
            </td>
            <td style="text-align: center;">
              {{ analytic._source.os_name }}
            </td>
            <td style="text-align: center;" v-if="analytic._source.referral"
                v-tooltip="analytic._source.referral">
              {{ limitTextLength(analytic._source.referral, 40) }}
            </td>
            <td style="text-align: center;" class="light_grey" v-else>
              N/A
            </td>

          </tr>

        </template>
        </tbody>

      </table>
    </div>
    <template v-if="!loader">

      <template v-if="total > limit">
        <pagination :clickedMethod="realtimeStatisticsPagination"
                    :resultCount="total"
                    :itemsPerPage="limit" :currentPage="page"
                    :totalPagesNumber="total_pages"></pagination>
      </template>

    </template>
  </div>

</template>

<script>

import {mapGetters, mapActions} from 'vuex'

import Dropdown from '@/ui/Dropdown.vue'
import {DARK_PURPLE_LOADER_COLOR} from '@/common/constants'

export default ({
  'name': 'AnalyticBrowser',
  'components': {
    Dropdown
  },
  data: function () {
    return {
      size: '20px',
      color: DARK_PURPLE_LOADER_COLOR,
      currentTab: 'tab1',
      realtime_sorting: 'visitor_time_desc',
      page: 1,
      list: [],
      total: 0,
      limit: 20,
      total_pages: 0,
      loader: false
    }
  },
  props: {
    forBio: false
  },
  async created() {
    this.page = 1
    this.fetchRealtimeStatistics()
  },
  computed: {
    ...mapGetters([
      'getAnalyticsDetail'
    ])
  },
  methods: {
    async fetchRealtimeStatistics() {
      setTimeout(async () => {
        if (this.forBio && this.realtime_sorting === 'visitor_time_asc') {
          this.realtime_sorting = 'created_at_asc'
          return;
        }
        if (this.forBio && this.realtime_sorting === 'visitor_time_desc') {
          this.realtime_sorting = 'created_at_desc'
          return;
        }
        if (!this.forBio && this.realtime_sorting === 'created_at_asc') {
          this.realtime_sorting = 'visitor_time_asc'
          return;
        }
        if (!this.forBio && this.realtime_sorting === 'created_at_desc') {
          this.realtime_sorting = 'visitor_time_desc'
          return;
        }
        const action = this.forBio ? 'bioRealtimeStatistics' : 'realtimeStatistics'
        this.loader = true
        let res = await this.$store.dispatch(action, {page: this.page, sort: this.realtime_sorting})
        this.loader = false
        if (res && res.data.status) {
          this.list = res.data.data.hits
          this.total = res.data.data.total
          this.total_pages = Math.round(this.total / this.limit)
        }
      }, 0)
    },
    realtimeStatisticsPagination(page) {
      this.page = page
      this.fetchRealtimeStatistics()
    },
    setSortVal() {
      if (this.forBio) {
        this.realtime_sorting = this.realtime_sorting === 'created_at_asc' ? 'created_at_desc' : 'created_at_asc'
      } else {
        this.realtime_sorting = this.realtime_sorting === 'visitor_time_asc' ? 'visitor_time_desc' : 'visitor_time_asc'
      }
    }
  },
  watch: {
    realtime_sorting(value) {
      this.page = 1
      this.fetchRealtimeStatistics()
    }
  }
})
</script>
